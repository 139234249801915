import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/categories/',
    name: 'categories',
    component: () => import('../views/CategoriesView.vue')
  },
  {
    path: '/categories/:categoryId',
    name: 'products',
    component: () => import('../views/ProductsView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/sales',
    name: 'sales',
    component: () => import('../views/SalesView.vue')
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('../views/ContactsView.vue')
  },
  {
    path: '/reviews',
    name: 'reviews',
    component: () => import('../views/ReviewsView.vue')
  },
  {
    path: '/prices',
    name: 'prices',
    component: () => import('../views/PricesView.vue')
  },
  {
    path: '/product/:id',
    name: 'product',
    component: () => import('../views/ProductView.vue')
  }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {x: 0, y: 0};
    }
});

router.beforeEach((to, from, next) => {
    next();
    if (!!router.app.$store) {
        let meta = router.app.$store.getters['getMetaInfo'];
        var product = '';
        if (to.name == 'product') {
          product = router.app.$store.getters['getServiceById'](to.params.id).title.ua;
        }
        let target = to.name;
        document.querySelector('title').innerText = product + meta.pages[target].title;
        document.head.querySelector('meta[name="description"]').setAttribute('content', meta.pages[target].description);
        document.head.querySelector('meta[name="keywords"]').setAttribute('content', meta.pages[target].keywords);
    }
})

export default router

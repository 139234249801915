<template>
    <header class="page-header" :class="{alt: isAlt}" v-if="!!content">
        <div class="mobile-menu" :class="{active: isMenuOpen}">
            <button class="close-menu-btn" @click="isMenuOpen = false"><span class="fas fa-times"></span></button>
            <div class="logo">
                <img src="./../assets/images/logo.png">
            </div>
            <nav class="list">
                <ul>
                    <li><router-link to="/">{{ content.nav.home[lang] }}</router-link></li>
                    <li><router-link to="/about">{{ content.nav.about[lang] }}</router-link></li>
                    <li><router-link to="/categories">{{ content.nav.services[lang] }}</router-link></li>
                    <li><router-link to="/prices">{{ content.nav.prices[lang] }}</router-link></li>
                    <li><router-link to="/sales">{{ content.nav.sales[lang] }}</router-link></li>
                    <li><router-link to="/contacts">{{ content.nav.contacts[lang] }}</router-link></li>
                </ul>
            </nav>
            <div class="action-pannel">
                <div class="lang-trigger">
                    <button :class="{selected: lang == 'ua'}" @click="setLang('ua')">UA</button>
                    <button :class="{selected: lang == 'ru'}" @click="setLang('ru')">RU</button>
                    <!-- <button :class="{selected: lang == 'en'}" @click="setLang('en')">EN</button> -->
                </div>
                <div class="socials">
                    <a v-if="contactsPageContent.socials.facebook.link" :href="contactsPageContent.socials.facebook.link" target="_blank" class="social facebook">
                        <span  class="fab fa-facebook"></span>
                    </a>
                    <a v-if="contactsPageContent.socials.instagram.link" :href="contactsPageContent.socials.instagram.link" target="_blank" class="social instagram">
                        <span  class="fab fa-instagram"></span>
                    </a>
                    <a v-if="contactsPageContent.socials.youtube.link" :href="contactsPageContent.socials.youtube.link" target="_blank" class="social youtube">
                        <span  class="fab fa-youtube"></span>
                    </a>
                    <a v-if="contactsPageContent.socials.whatsApp.link" :href="'https://wa.me/' + contactsPageContent.socials.whatsApp.link" target="_blank" class="social whatsapp">
                        <span  class="fab fa-whatsapp"></span>
                    </a>
                    <a v-if="contactsPageContent.socials.viber.link" :href="'viber://chat?number=' + contactsPageContent.socials.viber.link" target="_blank" class="social viber">
                        <span  class="fab fa-viber"></span>
                    </a>
                </div>
                <div class="action">
                    <div class="icon"><span class="fas fa-phone-alt"></span></div>
                    <div class="content">
                        <p class="value">{{ contactsPageContent.contacts.phone1.value }}</p>
                        <p class="info">Связаться с нами</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="top-line visible-md hidden-xs">
            <div class="wrapper">
                <div class="contacts">
                    <div class="item">
                        <router-link to="/contacts">
                            <span class="fas fa-map-marker-alt"></span>
                            {{ contactsPageContent.address[0].text[lang] }}
                        </router-link>
                    </div>
                    <div class="item">
                        <router-link to="/contacts">
                            <span class="fas fa-map-marker-alt"></span>
                            {{ contactsPageContent.address[1].text[lang] }}
                        </router-link>
                    </div>
                </div>
                <div class="socials visible-flex-md hidden-xs">
                    <a v-if="contactsPageContent.socials.facebook.link" :href="contactsPageContent.socials.facebook.link" target="_blank" class="social facebook">
                        <span  class="fab fa-facebook"></span>
                    </a>
                    <a v-if="contactsPageContent.socials.instagram.link" :href="contactsPageContent.socials.instagram.link" target="_blank" class="social instagram">
                        <span  class="fab fa-instagram"></span>
                    </a>
                    <a v-if="contactsPageContent.socials.youtube.link" :href="contactsPageContent.socials.youtube.link" target="_blank" class="social youtube">
                        <span  class="fab fa-youtube"></span>
                    </a>
                    <a v-if="contactsPageContent.socials.whatsApp.link" :href="'https://wa.me/' + contactsPageContent.socials.whatsApp.link" target="_blank" class="social whatsapp">
                        <span  class="fab fa-whatsapp"></span>
                    </a>
                    <a v-if="contactsPageContent.socials.viber.link" :href="'viber://chat?number=' + contactsPageContent.socials.viber.link" target="_blank" class="social viber">
                        <span  class="fab fa-viber"></span>
                    </a>
                </div>
            </div>
        </div>
        <div class="wrapper">
            <a href="/" class="logo-box">
                <img src="./../assets/images/logo.png">
                <span class="sub">{{ texts.logoSubTitle[lang] }}</span>
            </a>
            <div class="nav hidden-xs visible-lg">
                <ul>
                    <li><router-link to="/">{{ content.nav.home[lang] }}</router-link></li>
                    <li><router-link to="/about">{{ content.nav.about[lang] }}</router-link></li>
                    <li><router-link to="/categories">{{ content.nav.services[lang] }}</router-link></li>
                    <li><router-link to="/prices">{{ content.nav.prices[lang] }}</router-link></li>
                    <li><router-link to="/sales">{{ content.nav.sales[lang] }}</router-link></li>
                    <li><router-link to="/contacts">{{ content.nav.contacts[lang] }}</router-link></li>
                </ul>
            </div>
            <div class="actions">
                <div class="lang-trigger hidden-xs visible-flex-md">
                    <button :class="{selected: lang == 'ua'}" @click="setLang('ua')">UA</button>
                    <button :class="{selected: lang == 'ru'}" @click="setLang('ru')">RU</button>
                    <!-- <button :class="{selected: lang == 'en'}" @click="setLang('en')">EN</button> -->
                </div>
                <div class="action hidden-xs visible-flex-sm">
                    <div class="icon"><span class="fas fa-phone-alt"></span></div>
                    <div class="content">
                        <p class="value">{{ contactsPageContent.contacts.phone1.value }}</p>
                        <p class="info">{{ contactsPageContent.contacts.phone1.text[lang] }}</p>
                    </div>
                </div>
                <div class="action visible-flex-xs hidden-lg" @click="isMenuOpen = true">
                    <div class="icon"><span class="fas fa-bars"></span></div>
                    <div class="content">
                        <p class="value">{{ texts.menu[lang] }}</p>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    data() {
        return {
            isAlt: false,
            isMenuOpen: false,
            texts: {
                logoSubTitle: {
                    ua: 'Клініка',
                    ru: 'Клиника',
                    en: 'Clinic'
                },
                menu: {
                    ua: 'Меню',
                    ru: 'Меню',
                    en: 'Menu'
                }
            }
        }
    },  
    computed: {
        lang() {
            return this.$store.getters['getSelectedLang'];
        },
        content() {
            return this.$store.getters['getGeneralPageContent'].header;
        },
        contactsPageContent() {
            return this.$store.getters['getContactsPageContent'];
        }
    },
    methods: {
        setLang(lang) {
            this.$store.dispatch('setLang', lang)
        }
    },
    watch: {
        $route(to, from) {
            (to.path != '/') ? this.isAlt = false : this.isAlt = true; 
            this.isMenuOpen = false;
        }
    },  
    mounted() {
        window.addEventListener('scroll', (e) => {
            if (this.$route.path == '/') {
                (window.pageYOffset > 0) ? this.isAlt = false : this.isAlt = true;
            } 
        });
    }
}
</script>

<template>
    <footer class="page-footer" v-if="Object.keys(content).length">
       <div class="wrapper">
            <div class="top-bar">
                <div class="tp-item">
                    <div class="icon"><span class="fas fa-phone-alt"></span></div>
                    <div class="content">
                        <p class="value">{{ contactsPageContent.contacts.phone1.value }}</p>
                        <p class="info">{{ contactsPageContent.contacts.phone1.text[lang] }}</p>
                    </div>
                </div>
                <div class="tp-item">
                    <div class="icon"><span class="fas fa-phone-alt"></span></div>
                    <div class="content">
                        <p class="value">{{ contactsPageContent.contacts.phone2.value }}</p>
                        <p class="info">{{ contactsPageContent.contacts.phone2.text[lang] }}</p>
                    </div>
                </div>
                <div class="tp-item">
                    <div class="icon"><span class="fas fa-phone-alt"></span></div>
                    <div class="content">
                        <p class="value">{{ contactsPageContent.contacts.telegram.value }}</p>
                        <p class="info">{{ contactsPageContent.contacts.telegram.text }}</p>
                    </div>
                </div>
                <div class="tp-item">
                    <div class="icon"><span class="fas fa-phone-alt"></span></div>
                    <div class="content">
                        <p class="value">{{ contactsPageContent.contacts.email.value }}</p>
                        <p class="info">{{ contactsPageContent.contacts.email.text }}</p>
                    </div>
                </div>
            </div>
            <div class="main-bar">
                <div class="info">
                    <div class="logo"><img src="./../assets/images/logo.png"></div>
                    <p class="text">{{ content.footer.description[lang] }}</p>
                    <div class="socials">
                        <a :href="contactsPageContent.socials.facebook.link" target="_blank" class="social">
                            <span class="fab fa-facebook"></span>
                        </a>
                        <a :href="contactsPageContent.socials.instagram.link" target="_blank" class="social">
                            <span class="fab fa-instagram"></span>
                        </a>
                        <a :href="contactsPageContent.socials.youtube.link" target="_blank" class="social">
                            <span class="fab fa-youtube"></span>
                        </a>
                    </div>
                    <div class="docs">
                        <a target="_blank" :href="content.docs.privacyPolicy.link" class="doc">
                            <span class="fas fa-file-pdf"></span>
                            {{ content.docs.privacyPolicy.title[lang] }}
                        </a>
                        <a target="_blank" :href="content.docs.offer.link" class="doc">
                            <span class="fas fa-file-pdf"></span>
                            {{ content.docs.offer.title[lang] }}
                        </a>
                    </div>
                </div>
                <div class="nav">
                    <p class="nav-title">{{ texts.pages[lang] }}</p>
                    <ul>
                        <li><router-link to="/">{{ content.header.nav.home[lang] }}</router-link></li>
                        <li><router-link to="/about">{{ content.header.nav.about[lang] }}</router-link></li>
                        <li><router-link to="/categories">{{ content.header.nav.services[lang] }}</router-link></li>
                        <li><router-link to="/prices">{{ content.header.nav.prices[lang] }}</router-link></li>
                        <li><router-link to="/sales">{{ content.header.nav.sales[lang] }}</router-link></li>
                        <li><router-link to="/contacts">{{ content.header.nav.contacts[lang] }}</router-link></li>
                    </ul>
                </div>
                <div class="nav">
                    <p class="nav-title">{{ texts.services[lang] }}</p>
                    <div class="list-group">
                        <ul>
                            <li v-for="(product, index) in services[0]" :key="index"><router-link :to="'product/' + product.code">{{ product.title[lang] }}</router-link></li>
                        </ul>
                        <ul>
                            <li v-for="(product, index) in services[1]" :key="index"><router-link :to="'product/' + product.code">{{ product.title[lang] }}</router-link></li>
                        </ul>
                        <ul class="hidden-xs visible-lg">
                            <li v-for="(product, index) in services[2]" :key="index"><router-link :to="'product/' + product.code">{{ product.title[lang] }}</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="copyright">
                <p>{{ content.footer.copyright[lang] }}</p>
            </div>
       </div>
    </footer>
</template>

<script>
export default {
    data() {
        return {
            texts: {
                pages: {
                    ua: 'Сторінки',
                    ru: 'Страницы',
                    en: 'Pages'
                },
                services: {
                    ua: 'Послуги',
                    ru: 'Услуги',
                    en: 'Services'
                }
            }
        }
    },
    computed: {
        lang() {
            return this.$store.getters['getSelectedLang'];
        },
        content() {
            return this.$store.getters['getGeneralPageContent'];
        },
        contactsPageContent() {
            return this.$store.getters['getContactsPageContent'];
        },
        services() {
            let list = this.$store.getters['getServices'].filter(service => service.title[this.lang].length);
            return [
                list.reverse().slice(0, 6),
                list.reverse().slice(6, 12),
                list.reverse().slice(12, 18)
            ];
        }
    }
}
</script>

<template>
  <div id="app" data-content-area="1200px">
    <PageHeader/>
    <router-view/>
    <PageFooter/>
    <MobileNavPanel class="visible-xs hidden-md"/>
    <div class="loader-wrapper" :class="{'hidden': isLoaded}">
        <img src="./assets/images/logo.png" class="logo">
        <div class="loader line-scale-pulse-out">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
  </div>
</template>

<script>
import PageHeader from './components/PageHeader.vue';
import PageFooter from './components/PageFooter.vue';
import MobileNavPanel from './components/MobileNavPanel.vue';

export default {
    data() {
        return {
            isLoaded: false
        }
    },  
    components: {
        PageHeader,
        PageFooter,
        MobileNavPanel
    },
    created() {
        this.$store.dispatch('fetchCategories');
        this.$store.dispatch('fetchServices');
        this.$store.dispatch('fetchStaticContent');
        this.$store.dispatch('fetchMetaInfo');

        setTimeout(() => {
            this.isLoaded = true;

            if (!!this.$route.query.lang) {
                this.$store.dispatch('setLang', this.$route.query.lang);
            } else {
                if (!!localStorage.getItem('lang')) {
                    this.$store.dispatch('setLang', localStorage.getItem('lang'));
                }
            }
        }, 1000);
    }
}

</script>

<style lang="less">
    @import "./assets/less/index.less";
</style>

<template>
    <div class="mobile-nav-panel" v-if="Object.keys(contactsContent).length">
        <div class="btn-group">
            <a :href="'tg://resolve?domain=' + contactsContent.contacts.telegram.value" class="panel-btn telegram" target="_blank">
                <span class="btn-icon fab fa-telegram"></span>
            </a>
            <a :href="'viber://add?number=' + contactsContent.socials.viber.link" class="panel-btn viber" target="_blank">
                <span class="btn-icon fab fa-viber"></span>
            </a>
            <a :href="'https://wa.me/' + contactsContent.socials.whatsApp.link" class="panel-btn whatsapp" target="_blank">
                <span class="btn-icon fab fa-whatsapp"></span>
            </a>
            <a @click="scrollToSubmitForm()" class="panel-btn">
                <span class="btn-text">{{ btnText[lang] }}</span>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            btnText: {
                ua: 'Залишити заявку або придбати онлайн',
                ru: 'Оставить заявку или купить онлайн',
                en: 'Leave request or buy online'
            }
        }
    },
    props: {

    },
    methods: {
        scrollToSubmitForm() {
            let target = document.querySelector('.callback-form').offsetTop + document.querySelector('#cbform').offsetTop - 100;
            window.scrollTo({
                top: target,
                left: 0,
                behavior: "smooth",
            });
        }
    },
    computed: {
        lang() {
            return this.$store.getters['getSelectedLang'];
        },
        contactsContent() {
            return this.$store.getters['getContactsPageContent'];
        }
    }
}
</script>
